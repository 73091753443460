import React, { useState, useEffect } from 'react';
import { Button, Grid, Modal, Tabs } from '@mantine/core';
import { FaUserPlus, FaUserMinus } from 'react-icons/fa';
import { axios } from '../../../utils/axios';
import withConfirm from '../../../components/common/confirm-dialog';
import { tabStyles } from '../../../utils/helpers';
import { showAlert } from '../../../utils/alerts';
import { EditModalContainer } from './styles/editModal';
import { ListComponent } from './revoke';
import { Add } from './add';

const initialFormState = {
  role: null,
  country: null,
  producingPlant: null,
  typeOfRequest: null,
  typeOfSKU: null,
};

const initialOptions = {
  roles: [],
  countries: [],
  plantsByCountry: {},
  typeOfRequests: ['Costing', 'Transfer Pricing', 'Both'],
  typeOfSKUs: [],
  validators: {},
};

const EditModal = ({ state, close, confirm, setConfirmLoading, fetchUsers }) => {
  const [loading, setLoading] = useState(false);
  const [countryLoader, setCountryLoader] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [options, setOptions] = useState(initialOptions);
  const [activeTab, setActiveTab] = useState('addAccess');
  const [selectedRoles, setSelectedRoles] = useState([]);

  const {
    is_scoped_to_country,
    is_scoped_to_plant,
    is_scoped_to_type_of_sku,
    is_scoped_to_type_of_request,
  } = options.validators || {};

  useEffect(() => {
    setFormData(initialFormState);
    setOptions(initialOptions);
  }, [activeTab]);

  const fetchOptions = async (roleAlias) => {
    try {
      setCountryLoader(true);

      const response = await axios.get('/user/v2/options', {
        params: {
          role: roleAlias,
        },
      });

      const { data, zone_country_plant, skus } = response.data;

      setOptions((prevOptions) => ({
        ...prevOptions,
        countries: zone_country_plant ? Object.keys(zone_country_plant.EUROPE || {}) : [],
        plantsByCountry: zone_country_plant ? zone_country_plant.EUROPE || {} : {},
        typeOfSKUs: skus || [],
        typeOfRequests: prevOptions.typeOfRequests,
        validators: data,
      }));
    } catch (e) {
    } finally {
      setCountryLoader(false);
    }
  };

  const handleChange = (value, field) => {
    setFormData((prevState) => ({
      ...prevState,

      [field]: value,
    }));

    if (field === 'role') {
      fetchOptions(value);
    }
  };

  const handleSaveChanges = async () => {
    try {
      if (activeTab === 'addAccess') await handleAddAccess();
      if (activeTab === 'revokeAccess') await handleRevokeAccess();
      close();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Registration failed. Please try again.';
      showAlert({ message: errorMessage }, 'error');
    }
  };

  const handleAddAccess = async () => {
    setLoading(true);
    try {
      const activeUser = state.user.id;

      const payload = {
        zone: 'EUR',
        role_requested: formData.role,
        plant: formData.producingPlant || null,
        country: formData.country || null,
        type_of_sku: formData.typeOfSKU || null,
        type_of_request: formData.typeOfRequest || null,
        justification: formData.justification || null,
        status: 'Pending',
      };

      const response = await axios.post(`/user/v2/access/${activeUser}`, payload);

      setFormData(initialFormState);
      fetchUsers();
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleRevokeAccess = async () => {
    try {
      const revokeUser = state.user.id;

      const promises = selectedRoles.map(async (role_id) => {
        return await axios.delete(`/user/v2/access/${revokeUser}/${role_id}`, {});
      });

      await Promise.all(promises);

      fetchUsers();
    } catch (error) {
      console.error('Error revoking access:', error);
      throw error;
    }
  };

  const handleDelete = async (userId, permanentDelete = false) => {
    setConfirmLoading(true);
    try {
      await axios.delete(`/user/v2/${userId}`, {
        params: {
          force: permanentDelete,
        },
      });
      fetchUsers();
    } catch (error) {
    } finally {
      close();
      setConfirmLoading(false); 
    }
  };

  const validations = () => {
    const { role, country, producingPlant, typeOfRequest, typeOfSKU } = formData;

    return !(
      !role ||
      (is_scoped_to_country && !country) ||
      (is_scoped_to_plant && !producingPlant) ||
      (is_scoped_to_type_of_sku && !typeOfSKU) ||
      (is_scoped_to_type_of_request && !typeOfRequest)
    );
  };

  return (
    <Modal opened={state.open} onClose={() => close()} title={'Manage Access'} centered size='lg'>
      <EditModalContainer>
        <Tabs
          value={activeTab}
          onTabChange={setActiveTab}
          variant='unstyled'
          styles={tabStyles}
          color='primary'
        >
          <Tabs.List>
            <Tabs.Tab value='addAccess'>
              <FaUserPlus style={{ marginRight: '10px' }} />
              Add Access
            </Tabs.Tab>
            <Tabs.Tab value='revokeAccess'>
              <FaUserMinus style={{ marginRight: '10px' }} />
              Revoke Access
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='addAccess'>
            <div style={{ marginBottom: '20px', marginTop: '14px' }}>
              <Add
                handleChange={handleChange}
                formData={formData}
                options={options}
                loading={countryLoader}
              />
            </div>
          </Tabs.Panel>

          <Tabs.Panel value='revokeAccess'>
            <div style={{ marginBottom: '20px', marginTop: '14px' }}>
              <ListComponent
                data={state?.user?.int_user_role_mappings || []}
                selectedRoles={selectedRoles}
                setSelectedRoles={setSelectedRoles}
              />
            </div>
          </Tabs.Panel>
        </Tabs>

        <Grid justify='flex-end' gutter={'xs'}>
          <Grid.Col md={4} lg={4} xs={12} offset={5} offsetXs={0}>
            <Button
              variant='gradient'
              disabled={
                (activeTab === 'revokeAccess' && selectedRoles.length === 0) ||
                (activeTab === 'addAccess' && !validations()) ||
                loading ||
                countryLoader
              }
              color='primary'
              fullWidth={true}
              onClick={handleSaveChanges}
            >
              {activeTab === 'addAccess' ? 'Add User Access' : 'Revoke User Access'}
            </Button>
          </Grid.Col>
          <Grid.Col md={3} lg={3} xs={12}>
            <Button
              color='red'
              fullWidth={true}
              disabled={loading}
              onClick={() =>
                confirm(
                  (checkbox = {}) => {
                    const isPermanentDelete = checkbox.checkboxStatus || false;;
                    handleDelete(state.user.id, isPermanentDelete);
                  },
                  {
                    title: 'Confirm!',
                    description: 'Are you sure you want to delete user?',
                    confirmationText: 'Confirm',
                    displayCheckbox: true,
                    checkboxLabel: 'Permanently Delete?',
                  },
                )
              }
            >
              Delete User
            </Button>
          </Grid.Col>
        </Grid>
      </EditModalContainer>
    </Modal>
  );
};

export default withConfirm(EditModal);
