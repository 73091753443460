import { Button, Grid, Space, Tabs } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Table } from './table';
import { AppLoader } from '../../../../components/common/loader';
import { ViewBom } from './viewBom';
import { Col, Row } from 'react-bootstrap';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { ProjectOverview } from '../projectOverview';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { filterCommonSkus, filterUniqueSkus, getSelectedPlants, makePayload } from './helper';

export const ReferenceSKU = ({ activeTab }) => {
  const params = useParams();
  const { info } = useContext(CostingRequestsContext);
  const [loading, setLoading] = useState(false);
  const [bomModal, setBomsModal] = useState({
    open: false,
    sku: null,
    plant: null,
  });
  const [selectedSkus, setSelectSkus] = useState({});
  const [selectedSkusPayload, setSkusPayload] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [infoLoading, setInfoLoading] = useState(false);
  const [activePlant, setActivePlant] = useState();
  const [readOnly, setReadOnly] = useState(false);
  const [referenceSkus, setReferenceSkus] = useState({});

  const { producing_plant_data = [], sku_data = [] } = info;

  useEffect(() => {
    if (activeTab === 'referenceSKUSelection' && sku_data.length && producing_plant_data.length) {
      fetchSavedSkus();
      fetch();
      setActivePlant(producing_plant_data[0]?.producing_plant);
    }

    return () => {
      setSelectSkus({});
    };
  }, [activeTab, producing_plant_data, sku_data]);

  const fetchSavedSkus = async (loading = true) => {
    try {
      setInfoLoading(loading);
      const { data } = await axios.get(
        `/costing-request/step/selected-ref-sku-selection/${params.rfsNumber}`,
      );

      const plants = getSelectedPlants(data);
      const selectedPlantsLength = Object.keys(plants).length;

      setReadOnly(selectedPlantsLength);
      setSkusPayload({
        ...plants,
        allSelected: selectedPlantsLength > 1,
      });
    } catch (e) {
    } finally {
      setInfoLoading(false);
    }
  };

  const validate = () => {
    const error = {};
    Object.values(selectedSkusPayload || {}).forEach((item) => {
      if (Object.keys(item.selectedData || {}).length === 0 && item.production_plant_id)
        error[item.production_plant_id] = true;
    });

    return error;
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const validationErrors = validate();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(errors);
      } else {
        const payload = makePayload(selectedSkusPayload);
        await axios.post(`/costing-request/step/ref-sku-selection/${params.rfsNumber}`, payload);

        fetchSavedSkus(false);
      }
    } catch (e) {
    } finally {
      fetchSavedSkus(false);
      setSubmitLoading(false);
    }
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/costing-request/step/get-ref-sku-selection/${params.rfsNumber}`,
      );
      const { recommended, PS, pegasus } = data.data;
      const filteredSKus = filterUniqueSkus([...(PS ? [PS] : []), ...(pegasus || [])] || []);
      const skus = filterCommonSkus(recommended[0] || [], filteredSKus);

      setReferenceSkus(skus);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleRowSelection = (data, plantId, sku_id) => {
    let currentValues = { ...selectedSkus };
    currentValues = { ...data };

    setSkusPayload({
      ...selectedSkusPayload,
      [plantId]: {
        ...selectedSkusPayload[plantId],
        sku_id: sku_id,
        production_plant_id: plantId,
        selectedData: currentValues,
      },
    });
    setSelectSkus(currentValues);
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        <Button
          className='next'
          onClick={handleSubmit}
          variant='gradient'
          radius='md'
          size='md'
          fullWidth
          disabled={
            loading ||
            submitLoading ||
            Object.keys(validate() || {}).length > 0 ||
            selectedSkusPayload?.allSelected ||
            readOnly
          }
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
        >
          Submit
        </Button>
      </Col>
    </Row>
  );

  if (loading || infoLoading)
    return (
      <ContentPaper footer={<Footer />}>
        <ProjectOverview />
        <Space h='sm' />
        <AppLoader center size='md' />
      </ContentPaper>
    );

  return (
    <ContentPaper footer={<Footer />}>
      <ProjectOverview />
      <Space h='sm' />

      <div>
        <Tabs
          keepMounted={false}
          value={activePlant}
          onTabChange={(value) => setActivePlant(value)}
          color='yellow'
        >
          <Tabs.List>
            {producing_plant_data.map(({ producing_plant, id }, index) => (
              <Tabs.Tab value={producing_plant} className='menu-tabs'>
                {producing_plant}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {producing_plant_data.map(({ producing_plant, id }, index) => {
            const sku = sku_data[0] || null;
            if (!sku) return null;

            const recommendedSkus = (referenceSkus.recommended || [])
              .filter((item) => item.plant === producing_plant)
              .map((item) => ({
                ...item,
                unique: `${item.sku}_${item.plant}`,
                type: 'Recommended',
              }));

            const referenceSkusList = (referenceSkus.reference || []).map((item) => ({
              ...item,
              unique: `${item.sku}_${item.plant}`,
              type: 'RFS',
            }));

            const skus = [...recommendedSkus, ...referenceSkusList];

            return (
              <Tabs.Panel value={producing_plant}>
                <Grid>
                  <Grid.Col span={12}>
                    <Table
                      {...sku}
                      skuId={sku.id}
                      plantId={id}
                      skus={skus}
                      setBomsModal={setBomsModal}
                      handleRowSelection={handleRowSelection}
                      selectedSkus={selectedSkus}
                      selectedSkusDetails={selectedSkusPayload[id] || {}}
                      allSelected={selectedSkusPayload?.allSelected}
                      readOnly={readOnly}
                    />
                  </Grid.Col>
                </Grid>
              </Tabs.Panel>
            );
          })}
        </Tabs>
      </div>

      {bomModal.open && (
        <ViewBom
          bomModal={bomModal}
          handleClose={() => setBomsModal({ open: false, sku: null, plant: null })}
        />
      )}
    </ContentPaper>
  );
};
