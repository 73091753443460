import { Grid, Select } from '@mantine/core';
import React from 'react';
import { renderSkeletonLoader } from './helper';

const roles = [
  { value: 'scheduling', label: 'Scheduling' },
  { value: 'req', label: 'Requestor' },
  { value: 'standard/transfer_pricing', label: 'GCC FP&A' },
  { value: 'user_admin', label: 'User Admin' },
  { value: 'masterdata', label: 'MDM Spoc' },
  { value: 'fpna_brewery_manager', label: 'FPnA Brewery Manager' },
  { value: 'procurement', label: 'Procurement' },
  { value: 'repack', label: 'Repack' },
  { value: 'fpna_brewery_heads', label: 'FPnA Brewery Heads' },
];

export const Add = ({ handleChange, formData, options, loading }) => {
  const { role, country, producingPlant, typeOfRequest, typeOfSKU } = formData;
  const countries = options.countries;
  const plants = options.plantsByCountry[country] || [];
  const {
    is_scoped_to_country,
    is_scoped_to_plant,
    is_scoped_to_type_of_sku,
    is_scoped_to_type_of_request,
  } = options.validators || {};

  return (
    <>
      <Grid>
        <Grid.Col span={6}>
          <Select
            label='Select Role'
            placeholder='Select Role'
            onChange={(value) => handleChange(value, 'role')}
            data={roles}
            withinPortal
            withAsterisk
            value={role}
          />
        </Grid.Col>

        {(is_scoped_to_country || loading) && (
          <Grid.Col span={6}>
            {loading ? (
              renderSkeletonLoader(loading)
            ) : (
              <Select
                label='Select Country'
                placeholder='Select Country'
                onChange={(value) => handleChange(value, 'country')}
                data={countries}
                withinPortal
                withAsterisk
                value={country}
              />
            )}
          </Grid.Col>
        )}

        {is_scoped_to_plant && (
          <Grid.Col span={6}>
            <Select
              label='Select Producing Plant'
              placeholder='Select Producing Plant'
              onChange={(value) => handleChange(value, 'producingPlant')}
              data={plants}
              withinPortal
              withAsterisk
              value={producingPlant}
            />
          </Grid.Col>
        )}

        {is_scoped_to_type_of_sku && (
          <Grid.Col span={6}>
            <Select
              label='Select Type of SKU'
              placeholder='Select SKU'
              onChange={(value) => handleChange(value, 'typeOfSKU')}
              data={options.typeOfSKUs}
              withinPortal
              withAsterisk
              value={typeOfSKU}
            />
          </Grid.Col>
        )}
        {is_scoped_to_type_of_request && (
          <Grid.Col span={6}>
            <Select
              label='Select Type of Request'
              placeholder='Select Request'
              onChange={(value) => handleChange(value, 'typeOfRequest')}
              data={options.typeOfRequests}
              withinPortal
              withAsterisk
              value={typeOfRequest}
            />
          </Grid.Col>
        )}
      </Grid>
    </>
  );
};
