import { useMemo } from 'react';
import MantineTable from '../../../../common/table/mantine';

export const Table = ({ boms = [], loading }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Component',
        header: 'Component',
      },
      {
        accessorKey: 'ComponentDescription',
        header: 'Component Description',
      },
      {
        accessorKey: 'ComponentUnit',
        header: 'Component Unit',
      },
      {
        accessorKey: 'CostingRelevncy',
        header: 'Costing Relevncy',
      },
      {
        accessorKey: 'Currency',
        header: 'Currency',
      },
      {
        accessorKey: 'ItemCatogery',
        header: 'Item Catogery',
      },
      {
        accessorKey: 'ItemNo',
        header: 'Item No',
      },
      {
        accessorKey: 'LastPriceChange',
        header: 'Last Price Change',
      },
      {
        accessorKey: 'MatertialType',
        header: 'Matertial Type',
      },
      {
        accessorKey: 'MovingPrice',
        header: 'Moving Price',
      },
      {
        accessorKey: 'PlannedPrice1',
        header: 'Planned Price1 ',
      },
      {
        accessorKey: 'PlannedPriceDate1',
        header: 'Planned Price Date 1 ',
      },
      {
        accessorKey: 'PlantSpecificStatus',
        header: 'Plant Specific Status',
      },
      {
        accessorKey: 'PriceUnit',
        header: 'Price Unit',
      },
      {
        accessorKey: 'Quantity',
        header: 'Quantity',
      },
      {
        accessorKey: 'Scrap',
        header: 'Scrap',
      },
      {
        accessorKey: 'StandardPrice',
        header: 'Standard Price',
      },
      {
        accessorKey: 'Uom',
        header: 'Uom',
      },
      {
        accessorKey: 'Assembly',
        header: 'Assembly',
      },
      {
        accessorKey: 'ChangeNo',
        header: 'ChangeNo',
      },
      {
        accessorKey: 'ValidFrom',
        header: 'Valid From',
      },
      {
        accessorKey: 'ValidTo',
        header: 'Valid To',
      },
      {
        accessorKey: 'ValuationArea',
        header: 'Valuation Area',
      },
      {
        accessorKey: 'ValuationType',
        header: 'ValuationType',
      },
    ],
    [boms],
  );

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={boms}
        unique={'Component'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        enableExport={true}
        pageSize={10}
      />
    </>
  );
};
