import React, { Fragment, useState, useCallback, useEffect } from 'react';
import {
  Modal,
  Button,
  Container,
  Grid,
  Group,
  Text,
  Checkbox,
  Divider,
  Space,
} from '@mantine/core';
import { AppLoader } from '../loader';

const defaultOptions = {
  title: 'Are you sure?',
  description: '',
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  onClose: () => {},
  onCancel: () => {},
};

const withConfirm = (WrappedComponent) => (props) => {
  const [onConfirm, setOnConfirm] = useState(null);
  const [options, setOptions] = useState(defaultOptions);
  const {
    title,
    description,
    confirmationText,
    cancellationText,
    onClose,
    onCancel,
    checkboxLabel = null,
    displayCheckbox = false,
  } = options;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [checkboxStatus, setCheckboxStatus] = useState(false);

  const handleClose = useCallback(() => {
    onClose();
    setOnConfirm(null);
  }, [onClose]);

  const handleConfirm = useCallback(
    (...args) => {
      if (checkboxStatus) {
        onConfirm({ ...args, checkboxStatus });
      } else {
        onConfirm(...args);
      }
      if (!confirmLoading) handleClose();
    },
    [onConfirm, handleClose, confirmLoading],
  );

  function confirm(onConfirm, options = {}) {
    setOnConfirm(() => onConfirm);
    setOptions({ ...defaultOptions, ...options });
  }

  return (
    <Fragment>
      <WrappedComponent {...props} confirm={confirm} setConfirmLoading={setConfirmLoading} />

      <Modal
        opened={!!onConfirm}
        onClose={handleClose}
        title={title ? title : ''}
        className='confirm-modal'
      >
        <Container>
          <Grid>
            <Grid.Col span={12}>
              <Group noWrap className='invalid-modal-group'>
                {description && <Text size='sm'>{description}</Text>}
              </Group>
            </Grid.Col>

            {/* whitesapce */}
            <Grid.Col span={12}>
              <Space h='md' />
            </Grid.Col>

            <Grid.Col span={9}>
              {displayCheckbox && (
                <Checkbox
                  label={checkboxLabel}
                  checked={checkboxStatus}
                  onChange={(event) => setCheckboxStatus(event.currentTarget.checked)}
                />
              )}
            </Grid.Col>

            <Grid.Col span={3}>
              <Button
                disabled={confirmLoading}
                variant='gradient'
                onClick={() => handleConfirm()}
                fullWidth
              >
                {confirmLoading ? <AppLoader /> : confirmationText}
              </Button>
            </Grid.Col>
          </Grid>
        </Container>
      </Modal>
    </Fragment>
  );
};

export default withConfirm;
