import { Button, Grid, Tabs } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from './table';
import { IconRefresh } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { axios } from '../../../../../utils/axios';
import { AttachmentsModal } from '../../../../../common/attachments';
import { attachmentChunks } from '../../../../../utils/helpers';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';

const MAX_ATTACHMENTS = 5;

export const CostComponent = ({ activeTab }) => {
  const { info } = useContext(CostingRequestsContext);
  const { rfsNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activePlant, setActivePlant] = useState();
  const [attachmentModal, setAttachmentModal] = useState({
    open: false,
    artwork_dev: null,
    status_id: null,
    attachments: [],
  });

  const { producing_plant_data = [], sku_data = [] } = info;

  useEffect(() => {
    if (activeTab === 'vicSubmission' && sku_data.length && producing_plant_data.length) {
      fetch(false, producing_plant_data[0]?.producing_plant);
      setActivePlant(producing_plant_data[0]?.producing_plant);
    }
  }, [activeTab, producing_plant_data, sku_data]);

  const fetch = async (refresh = false, plant) => {
    try {
      setLoading(true);
      const response = await axios.get(`costing-request/step/vic-submission/${rfsNumber}`, {
        params: {
          refresh_vic_cc: refresh,
          plant,
        },
      });
      setData(response.data.data.cc_steps);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const openUpladModal = () => {
    setAttachmentModal({ open: true, artwork_dev: null, status_id: null, attachments: [] });
  };

  const handleRefresh = (plant) => {
    fetch(true, plant);
  };
  const handleSubmit = () => {};

  const handleFileSelect = (files) => {
    const currentValues = { ...attachmentModal };
    const attachments = currentValues.attachments || [];
    const filesLength = attachments.length + files.length;

    if (filesLength <= MAX_ATTACHMENTS) {
      files.forEach((file) => attachments.push(file));
      currentValues.attachments = attachments;
      setAttachmentModal(currentValues);
    }
  };

  const handleFileRemove = (index) => {
    const currentValues = { ...attachmentModal };
    currentValues.attachments = currentValues.attachments.filter((i, k) => k !== index);

    setAttachmentModal(currentValues);
  };

  const handleAttachmentSubmit = async () => {
    const { artwork_dev, attachments } = attachmentModal;
    const oldAttachments =
      (artwork_dev.gfp_attachment || '').length > 0 ? artwork_dev.gfp_attachment.split(',') : [];

    const attachmentNames = new Set(attachments.map((file) => file.name));
    const deletedAttachments = oldAttachments.filter((name) => !attachmentNames.has(name));

    const formData = new FormData();
    formData.append('rfs_number', rfsNumber);
    formData.append('id', attachmentModal.artwork_dev.id);

    for (const item of deletedAttachments) {
      formData.append('deletedAttachments[]', item);
    }

    const chunks = attachmentChunks(attachments.filter((file) => file.size));
    if (Object.keys(chunks).length > 0) {
      for (const chunk of Object.values(chunks)) {
        for (const file of chunk) {
          formData.append('attachments', file);
        }
        await uploadAttachment(formData);
      }
    } else {
      await uploadAttachment(formData);
    }

    setAttachmentModal({ ...attachmentModal, open: false });
    fetch(false);
  };

  const uploadAttachment = async (formData) => {
    try {
      const res = await axios.post(`/rfs/artwork/attachment`, formData);
      return res;
    } catch (error) {
      console.error('Error while handling attachment submission:', error);
    }
  };

  const rightButtons = [
    {
      label: <IconRefresh size={16} />,
      onClick: () => {
        handleRefresh(true, activePlant);
      },
    },
    {
      button: (
        <Button variant='gradient' color='dark' onClick={() => openUpladModal()}>
          Upload Attachment
        </Button>
      ),
    },
  ];

  return (
    <>
      <Tabs
        keepMounted={false}
        value={activePlant}
        onTabChange={(value) => setActivePlant(value)}
        color='yellow'
      >
        <Tabs.List>
          {producing_plant_data.map(({ producing_plant, id }, index) => (
            <Tabs.Tab value={producing_plant} className='menu-tabs' key={index}>
              {producing_plant}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {producing_plant_data.map(({ producing_plant, id }, index) => {
          return (
            <Tabs.Panel value={producing_plant} key={index}>
              <Grid>
                <Grid.Col span={12}>
                  <div>
                    <Table
                      data={data.filter((item) => item.pp_id === id)}
                      loading={loading}
                      rightButtons={rightButtons}
                    />
                  </div>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          );
        })}
      </Tabs>

      {attachmentModal.open && (
        <AttachmentsModal
          modal={attachmentModal}
          setModal={setAttachmentModal}
          onChange={handleFileSelect}
          onSubmit={handleAttachmentSubmit}
          onRemove={handleFileRemove}
          readOnly={false}
        />
      )}
    </>
  );
};
