import { useContext, useEffect, useMemo, useState } from 'react';
import { Checkbox, TextInput, Button, Tabs, Switch, Space, NumberInput } from '@mantine/core';
import MantineTable from '../../../../common/table/mantine';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { IconRefresh, IconTrash } from '@tabler/icons-react';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { showAlert } from '../../../../utils/alerts';
import { ProjectOverview } from '../projectOverview';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import styled from '@emotion/styled';
import { IconPlus } from '@tabler/icons-react';
import { formatSkus, checkIfFlagged } from './helper';
const FooterContainer = styled.div`
  float: right;
  button {
    width: 200px;
  }
`;

const CenterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const initialData = {
  delete_component: [],
  update_component: [],
  add_component: [],
};

export const BudgetBomAdjustment = ({ setLogsModal, activeTab }) => {
  const { rfsNumber } = useParams();
  const { info } = useContext(CostingRequestsContext);
  const [loading, setLoading] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [baseSkus, setBaseSkus] = useState([]);
  const [componentsDict, setComponentsDict] = useState(initialData);
  const [activePlant, setActivePlant] = useState();
  const [skuID, setSkuID] = useState();
  const { producing_plant_data = [], sku_data = [] } = info;

  useEffect(() => {
    if (activeTab === 'budgetBomAdjustment' && sku_data.length && producing_plant_data.length) {
      fetch();
      setActivePlant(producing_plant_data[0]?.producing_plant);
    }
  }, [activeTab, producing_plant_data, sku_data]);

  useEffect(() => {
    setComponentsDict(initialData);
  }, [activePlant]);

  const fetch = async (refresh = false) => {
    setLoading(true);
    try {
      if (refresh) await axios.get(`/costing-request/step/refresh-bom-adjustment/${rfsNumber}`);
      const { data } = await axios.get(`/costing-request/step/bom-adjustment-data/${rfsNumber}`);
      const skus = formatSkus(data.requestsku, data.referencesku);
      setBaseSkus(skus);
      setSkuID(data.requestsku?.[0]?.sku_id);
    } catch (e) {
      showAlert('no data available', 'error');
    } finally {
      setLoading(false);
    }
  };
  const handleMaterialAdd = (data) => {
    const newData = data['RequestedSkuCostEstimation'];
    delete data.RequestedSkuCostEstimation;
    const finalData = {
      ...data,
      isReference: false,
      hasReference: true,
      flagged: false,
      PrimaryRefSkuCostEstimation: newData,
      reference: data,
      is_add_component:true,
      req_plant_id: activePlant,
      req_sku_id: skuID,
    };
    setComponentsDict((prevData) => {
      return {
        ...prevData,
        add_component: [
          ...prevData.add_component.filter((comp) => comp.component !== data.component),
          finalData,
        ],
      };
    });
    setBaseSkus((prevData) => {
      return prevData.filter((sku) => sku.component !== data.component).concat(finalData);
    });
  };

  const handleSubmit = async () => {
    const payload = componentsDict;
    let msg = true;
    try {
      const res = await axios.post(`/costing-request/step/bom-updates/${rfsNumber}`, payload);
      if (res.data?.success) {
        msg = false;
      }
      showAlert({ message: 'Request Submitted Successfully' }, 'success');
    } catch (err) {
      msg = false;
      showAlert(err, 'error');
    } finally {
      if (msg) {
        showAlert({ message: 'Request Submitted, await results' }, 'success');
      }
    }
  };

  const handleInputChange = (component, field, value, componentToUpdate) => {
    const updateSkuData = (data) => {
      return data.map((sku) => {
        if (sku?.component === component) {
          let updatedSku;
          let updatedRefData;
          if (field === 'costing_relevancy'){
            updatedRefData = {
              ...sku.RequestedSkuCostEstimation,
            };
          }else{
             updatedRefData = {
              ...sku.RequestedSkuCostEstimation,
              total_value: '',
            };
          }
          updatedSku = {
            ...sku,
            RequestedSkuCostEstimation: updatedRefData,
            isChanged: true,
            [field]: value,
          };
          const isFlagged = checkIfFlagged(updatedSku, componentToUpdate.reference);
          return {
            ...updatedSku,
            flagged: isFlagged,
          };
        }
        return sku;
      });
    };
    setBaseSkus((prevDisplayData) => [...updateSkuData(prevDisplayData)]);
    setComponentsDict((prevDict) => {
      const updatedComponent = { ...componentToUpdate, [field]: value };
      return {
        ...prevDict,
        update_component: [
          ...prevDict.update_component.filter((comp) => comp.component !== component),
          updatedComponent,
        ],
      };
    });
  };

  const handleMaterialRemoval = (component, data) => {
    setBaseSkus((prevBaseSkus) => prevBaseSkus.filter((item) => item.component !== component));
    setComponentsDict((prevDict) => ({
      ...prevDict,
      delete_component: [...prevDict.delete_component, data],
    }));
  };

  const handleAutoAdjustment = () => {
    setBaseSkus((prevData) =>
      prevData.map((ele) => {
        const updatedEle = { ...ele };
        if (!ele.isReference) {
          if (ele.reference?.quantity && ele.reference?.quantity !== updatedEle.quantity) {
            updatedEle.quantity = ele.reference.quantity;
            updatedEle.isChanged = true;
            if (ele.RequestedSkuCostEstimation) {
              updatedEle.RequestedSkuCostEstimation = {
                ...ele.RequestedSkuCostEstimation,
                total_value: '',
              };
            }
          }
          if (ele.reference?.scrap && updatedEle.scrap !== ele.reference.scrap) {
            updatedEle.scrap = ele.reference.scrap;
            updatedEle.isChanged = true;
            if (ele.RequestedSkuCostEstimation) {
              updatedEle.RequestedSkuCostEstimation = {
                ...ele.RequestedSkuCostEstimation,
                total_value: '',
              };
            }
          }
          if (updatedEle?.costing_relevancy && ele.reference?.costing_relevancy && (updatedEle?.costing_relevancy !== ele.  reference?.costing_relevancy)) {
            updatedEle.costing_relevancy = ele.reference.costing_relevancy;
            updatedEle.isChanged = true;
          }
          
          updatedEle.flagged = checkIfFlagged(updatedEle, updatedEle.reference);
          if (updatedEle.isChanged) {
            setComponentsDict((prevDict) => {
              const updatedComponent = { ...updatedEle };
              return {
                ...prevDict,
                update_component: [
                  ...prevDict.update_component.filter(
                    (comp) => comp.component !== updatedEle.component,
                  ),
                  updatedComponent,
                ],
              };
            });
          }
          return updatedEle;
        }
        return ele;
      }),
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'actions',
        header: 'Actions',
        Cell: ({ row }) => {
          return (
            <>
              {!row.original.isReference ? (
                <CenterItem>
                  <Button
                    variant='subtle'
                    color='red'
                    size='md'
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMaterialRemoval(row.original?.component, row.original);
                    }}
                  >
                    <IconTrash size={16} />
                  </Button>
                </CenterItem>
              ) : (
                <CenterItem>
                  <Button color='green' size='md' onClick={(e) => handleMaterialAdd(row.original)}>
                    <IconPlus size={16} />
                  </Button>
                </CenterItem>
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'component',
        header: 'Material Number',
        sticky: 'left',
      },
      {
        accessorKey: 'component_description',
        header: 'Material Description',
        sticky: 'left',
      },
      {
        accessorKey: 'item_category',
        header: 'ICt',
        grow: false,
        size: 5,
      },
      {
        accessorKey: 'component_unit',
        header: 'UOM',
        grow: false,
        size: 5,
      },
      {
        id: 'quantity',
        header: 'Qty',
        Cell: ({ row }) => {
          let isDifferent;
          if (row.original.reference?.quantity) {
            isDifferent =
              parseFloat(row.original?.quantity) !== parseFloat(row.original.reference?.quantity);
          } else {
            isDifferent = false;
          }

          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  {row.original.isReference ? 'Ref' : 'Req:'}{' '}
                </span>
                <NumberInput
                  precision={2}
                  min={0}
                  step={0.01}
                  value={parseFloat(row.original?.quantity ?? 0) ?? 0}
                  disabled={row.original.isReference}
                  styles={(theme) => ({
                    input: {
                      borderColor: isDifferent ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                    },
                  })}
                  onChange={(value) => {
                    const newValue = value || 0;
                    handleInputChange(row.original?.component, 'quantity', newValue, row.original);
                  }}
                />
              </div>
              {row.original.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Ref: </span>
                  <TextInput disabled value={row.original.reference?.quantity ?? ''} />
                </div>
              )}
            </div>
          );
        },
      },
      {
        id: 'costing_relevancy',
        header: 'Costing Relevancy',
        grow: false,
        minSize: 40,
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  {row.original.isReference ? 'Ref' : 'Req:'}
                </span>
                <Checkbox
                  checked={row.original?.costing_relevancy ?? true}
                  disabled={row.original.isReference}
                  onChange={(e) =>
                    handleInputChange(
                      row.original?.component,
                      'costing_relevancy',
                      e.currentTarget.checked,
                      row.original,
                    )
                  }
                />
              </div>
              {row.original.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <span style={{ marginRight: '8px' }}>Ref :</span>
                  <Checkbox checked={row.original?.reference?.costing_relevancy} disabled />
                </div>
              )}
            </div>
          );
        },
      },
      {
        id: 'scrap',
        header: 'Scrap',
        Cell: ({ row }) => {
          const isDifferent = row.original?.reference?.scrap
            ? row.original?.scrap !== row.original?.reference?.scrap
            : false;
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  {row.original.isReference ? 'Ref' : 'Req:'}
                </span>
                <NumberInput
                  precision={2}
                  min={0}
                  step={0.01}
                  disabled={row.original.isReference}
                  value={parseFloat(row.original?.scrap) ?? 0}
                  styles={(theme) => ({
                    input: {
                      borderColor: isDifferent ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                    },
                  })}
                  onChange={(value) => {
                    const newValue = value || 0;
                    handleInputChange(row.original?.component, 'scrap', newValue, row.original);
                  }}
                />
              </div>
              {row.original.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Ref:</span>
                  <NumberInput
                    precision={2}
                    min={0}
                    step={0.01}
                    disabled
                    value={parseFloat(row.original?.reference?.scrap ?? '')}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        id: 'total_value',
        header: 'Total Value',
        Cell: ({ row }) => {
          const isDifferent = row.original?.reference?.PrimaryRefSkuCostEstimation?.total_value
            ? parseFloat(row.original?.RequestedSkuCostEstimation?.total_value) !==
              parseFloat(row.original?.reference?.PrimaryRefSkuCostEstimation?.total_value)
            : false;
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  {row.original.isReference ? 'Ref' : 'Req:'}{' '}
                </span>
                <TextInput
                  value={row.original?.RequestedSkuCostEstimation?.total_value ?? ''}
                  disabled
                  styles={(theme) => ({
                    input: {
                      borderColor: isDifferent ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                    },
                  })}
                />
              </div>
              {row.original.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Ref:</span>
                  <TextInput
                    disabled
                    value={row.original?.reference?.PrimaryRefSkuCostEstimation?.total_value ?? ''}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: 'currency',
        header: 'Curr',
        grow: false,
        minSize: 40,
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => <span>{row.original?.currency}</span>,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        grow: false,
        minSize: 40,
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => <span>{row.original?.RequestedSkuCostEstimation?.price}</span>,
      },
      {
        accessorKey: 'un',
        header: 'Price Unit',
        grow: false,
        minSize: 40,
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => <span>{row.original?.RequestedSkuCostEstimation?.un}</span>,
      },
      {
        accessorKey: 'price_unit',
        header: 'Price Per Unit',
        grow: false,
        minSize: 40,
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => <span>{row.original?.RequestedSkuCostEstimation?.price_unit}</span>,
      },
    ],
    [baseSkus],
  );

  const Footer = () => (
    <FooterContainer>
      <Button
        onClick={handleSubmit}
        variant='gradient'
        radius='md'
        size='md'
        fullWidth
        disabled={loading}
      >
        Submit
      </Button>
    </FooterContainer>
  );
  const rightSideBtns = [
    {
      label: <IconRefresh size={16} />,
      onClick: () => fetch(true),
    },
    {
      label: 'Auto Adjustment of BOM',
      onClick: () => handleAutoAdjustment(),
    },
  ];

  return (
    <>
      <ContentPaper footer={<Footer />}>
        <ProjectOverview />
        <Space h='sm' />
        <Tabs value={activePlant} onTabChange={(value) => setActivePlant(value)} color='yellow'>
          <Tabs.List>
            {producing_plant_data.map(({ producing_plant, id }, index) => (
              <Tabs.Tab value={producing_plant} className='menu-tabs'>
                {producing_plant}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {producing_plant_data.map(({ producing_plant, id }, index) => {
            return (
              <Tabs.Panel value={producing_plant}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                    marginTop: '10px',
                  }}
                >
                  <Switch
                    label='View Flagged Data Only'
                    size='md'
                    onChange={(e) => setSwitchState(e.target.checked)}
                  />
                </div>

                <MantineTable
                  columns={columns}
                  initialData={baseSkus.filter(
                    (item) =>
                      (item?.producingPlant?.producing_plant === producing_plant ||
                        item?.pp_id === producing_plant) &&
                      (!switchState || (switchState && item.flagged)),
                  )}
                  enablePinning
                  initialState={{
                    columnPinning: {
                      left: ['actions', 'component', 'component_description'],
                      right: [],
                    },
                  }}
                  unique={'component'}
                  enableRowSelection={false}
                  loading={loading}
                  showSelectedToggle={false}
                  hideSelectColumn={true}
                  showResetAll={false}
                  enableExport={true}
                  pageSize={10}
                  enableSelectAll={false}
                  rightButtons={rightSideBtns}
                />
              </Tabs.Panel>
            );
          })}
        </Tabs>
      </ContentPaper>
    </>
  );
};
