import MantineTable from '../../../../common/table/mantine';
import { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Button, Checkbox, Grid, NumberInput, Skeleton, Switch, Tabs } from '@mantine/core';
import { CustomTextArea } from '../../../../common/inputs/CustomTextArea';
import { IconRefresh } from '@tabler/icons-react';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { AppLoader } from '../../../../components/common/loader';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { AuthContext } from '../../../../contexts/AuthContext';
import { showAlert } from '../../../../utils/alerts';

export const Packmat = ({ activeSubTab, loading, skus, fetch, activePlant, setActivePlant }) => {
  const { rfsNumber } = useParams();
  const { info } = useContext(CostingRequestsContext);
  const {
    user: { user: userId },
  } = useContext(AuthContext);
  const [saveLoader, setSaveLoader] = useState({});
  const [form, setForm] = useState({});
  const [onlyEditable, setOnlyEditable] = useState(false);

  const { producing_plant_data = [] } = info;

  const handleSubmit = async (data) => {
    try {
      const {
        id,
        cost_value,
        material_number,
        production_plant,
        not_procurement_material,
        planned_price_date_1,
      } = data;
      const payload = {
        ...form[id],
        not_procurement_material: form[id].hasOwnProperty('not_procurement_material')
          ? form[id].not_procurement_material
          : not_procurement_material,
        planned_price_1: form[id].planned_price_1 ? form[id].planned_price_1 : cost_value,
        planned_price_date_1: planned_price_date_1 || moment().format('YYYY-MM-DD'),
        material: material_number,
        plant: production_plant,
      };

      setSaveLoader({ ...saveLoader, [id]: true });
      setForm({ ...form, [id]: null });
      await axios.post('costing-request/step/bom-component-price', payload);
      await fetch();
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Server error. Please try again.';
      showAlert({ message: errorMessage }, 'error');
    } finally {
      setSaveLoader({ ...saveLoader, [data.id]: false });
    }
  };

  const handleChange = (value, name, id, component_id) => {
    const values = { ...form };
    if (!values[id])
      values[id] = {
        costing_request_id: rfsNumber,
        id: id,
        component_id: component_id,
      };
    values[id][name] = value;

    setForm(values);
  };

  const getNumberInputValue = (costValue, formComment) => {
    return parseFloat(costValue || 0) || parseFloat(formComment || 0) || 0;
  };

  const isDisabled = (data) => {
    const {
      cost_value,
      procurement_spoc_id,
      fpna_analyst_responsible_id,
      not_procurement_material,
      id,
    } = data;

    const costValueDisabled = cost_value > 0;

    const everythingDisabled =
      (userId !== procurement_spoc_id && userId !== fpna_analyst_responsible_id) ||
      costValueDisabled;

    const notProcurementMaterialDisabled = not_procurement_material
      ? fpna_analyst_responsible_id !== userId
      : procurement_spoc_id !== userId;

    return {
      cost_value:
        (form[id]?.not_procurement_material && fpna_analyst_responsible_id !== userId) ||
        notProcurementMaterialDisabled ||
        everythingDisabled,
      not_procurement_material:
        (fpna_analyst_responsible_id === userId && procurement_spoc_id !== userId) ||
        notProcurementMaterialDisabled ||
        costValueDisabled,
      comment: everythingDisabled,
      submit: everythingDisabled,
    };
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'sku_number',
        header: 'SKU Number',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Description',
      },
      {
        accessorKey: 'material_number',
        header: 'Material Number',
      },
      {
        accessorKey: 'material_description',
        header: 'Material Description',
      },
      {
        accessorKey: 'material_status',
        header: 'Material Status',
      },

      {
        accessorKey: 'not_procurement_material',
        header: 'Not a Procurement Material',
        Cell: ({ row }) => {
          const { not_procurement_material, component_id, id } = row.original;
          return (
            <Skeleton visible={saveLoader[id] || false}>
              <Checkbox
                label=''
                checked={form[id] ? form[id].not_procurement_material : not_procurement_material}
                onChange={(event) =>
                  handleChange(event.target.checked, 'not_procurement_material', id, component_id)
                }
                disabled={isDisabled(row.original).not_procurement_material}
              />
            </Skeleton>
          );
        },
      },
      {
        accessorKey: 'procurement_spoc',
        header: 'Procurement SPOC',
      },
      {
        accessorKey: 'urgency',
        header: 'Urgency',
      },
      {
        accessorKey: 'producing_plant',
        header: 'Producing Plant',
      },
      {
        accessorKey: 'base_unit',
        header: 'Base Unit',
      },
      {
        accessorKey: 'uom',
        header: 'UOM',
      },
      {
        accessorKey: 'currency',
        header: 'Currency',
      },
      {
        accessorKey: 'cost_value',
        header: 'Cost Value',
        Cell: ({ row }) => {
          const { cost_value, component_id, id } = row.original;
          return (
            <Skeleton visible={saveLoader[id] || false}>
              <NumberInput
                value={getNumberInputValue(form[id]?.planned_price_1, cost_value)}
                precision={2}
                min={0}
                step={0.01}
                disabled={isDisabled(row.original).cost_value}
                onChange={(value) => handleChange(value, 'planned_price_1', id, component_id)}
              />
            </Skeleton>
          );
        },
      },
      {
        accessorKey: 'pack_mat_price_requesting_date',
        header: 'Requested On',
      },
      {
        accessorKey: 'price_updated',
        header: 'Submitted On',
      },

      {
        accessorKey: 'fpna_analyst_responsible',
        header: 'FPNA Analyst responsible',
      },
      {
        accessorKey: 'comments',
        header: 'Comments',
        Cell: ({ row }) => {
          const { comment, component_id, id, cost_value } = row.original;

          return (
            <Skeleton visible={saveLoader[id] || false}>
              <CustomTextArea
                radius='md'
                value={form[id] ? form[id]?.comment : comment || ''}
                name='comment'
                onChange={(value) => handleChange(value, 'comment', id, component_id)}
                disabled={isDisabled(row.original).comment}
              />
            </Skeleton>
          );
        },
      },
      {
        id: 'action',
        header: 'Action',
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Button
              disabled={!form[id] || saveLoader[id] || isDisabled(row.original).submit}
              color='dark'
              fullWidth
              size='sm'
              radius='sm'
              className='add-supplier-button'
              onClick={() => handleSubmit(row.original)}
            >
              {saveLoader[id] ? <AppLoader size='sm' /> : 'Submit'}
            </Button>
          );
        },
      },
    ],
    [skus, form, saveLoader, onlyEditable],
  );

  const rightButtons = [
    {
      label: <IconRefresh size={16} />,
      onClick: () => {
        setForm({});
        fetch(true);
      },
    },
  ];

  const leftButtons = [
    {
      button: (
        <Switch
          label=''
          checked={onlyEditable}
          onChange={(event) => setOnlyEditable(event.currentTarget.checked)}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs
        keepMounted={false}
        value={activePlant}
        onTabChange={(value) => setActivePlant(value)}
        color='yellow'
      >
        <Tabs.List>
          {producing_plant_data.map(({ producing_plant }, index) => (
            <Tabs.Tab value={producing_plant} key={index} className='menu-tabs'>
              {producing_plant}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {producing_plant_data.map(({ producing_plant, id }, index) => {
          return (
            <Tabs.Panel value={producing_plant} key={index}>
              <Grid>
                <Grid.Col span={12}>
                  <MantineTable
                    columns={columns}
                    initialData={(skus || []).filter(
                      (sku) =>
                        !sku.asm &&
                        sku.producing_plant === producing_plant &&
                        (!onlyEditable || !(sku.cost_value > 0)),
                    )}
                    unique={'sku'}
                    enableRowSelection={false}
                    loading={loading}
                    showSelectedToggle={false}
                    hideSelectColumn={true}
                    showResetAll={false}
                    rightButtons={rightButtons}
                    leftButtons={leftButtons}
                    enableExport={true}
                    csvFilename={`bom_component_price_packmat_${moment().format('YYYY-MM-DD')}`}
                    pageSize={10}
                  />
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          );
        })}
      </Tabs>
    </>
  );
};
