import { useMemo, useState } from 'react';
import MantineTable from '../../../../../common/table/mantine';
import { CustomTextArea } from '../../../../../common/inputs/CustomTextArea';

export const Table = ({ data = [], loading, rightButtons }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'cost_component',
        header: 'CC Code',
      },
      {
        accessorKey: 'cc_description',
        header: 'Name of CC',
      },
      {
        header: 'Overall Value',
        Cell: ({ row }) => {
          const { ref_v, overall_cost, sku_id } = row.original;
          const { overall_cost: ref_overall_cost } = ref_v[0] || {};
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={sku_id || 'key-1'} style={{ marginRight: '8px' }}>
                  Requested: {overall_cost}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Reference: {ref_overall_cost}</span>
              </div>
            </div>
          );
        },
      },
      {
        header: 'Fixed Value',
        Cell: ({ row }) => {
          const { ref_v, fixed_cost } = row.original;
          const { fixed_cost: ref_fixed_cost } = ref_v[0] || {};
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Requested: {fixed_cost}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Reference: {ref_fixed_cost}</span>
              </div>
            </div>
          );
        },
      },
      {
        header: 'Variable Value',
        Cell: ({ row }) => {
          const { ref_v, variable_cost } = row.original;
          const { variable_cost: ref_variable_cost } = ref_v[0] || {};
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Requested: {variable_cost}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Reference: {ref_variable_cost}</span>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'currency',
        header: 'Currency Unit',
      },

      {
        header: 'Comment',
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ alignItems: 'center', marginBottom: '8px' }}>
                <CustomTextArea value={row.original?.comment} placeholder='Comment here' />
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={data}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        pageSize={10}
        enableColumnFilter={false}
        enableFilters={false}
        enableExport={true}
        columnFilterDisplayMode={false}
        rightButtons={rightButtons}
      />
    </>
  );
};
