import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Checkbox, NumberInput, Button, Tabs, Switch, Space, TextInput } from '@mantine/core';
import { axios } from '../../../../utils/axios';
import MantineTable from '../../../../common/table/mantine';
import { useParams } from 'react-router-dom';
import { IconRefresh, IconTrash } from '@tabler/icons-react';
import { CustomTextArea } from '../../../../common/inputs/CustomTextArea';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';


export const NormsCheck = ({ activeTab }) => {
  const { rfsNumber } = useParams();
  const { info } = useContext(CostingRequestsContext);

  const [loading, setLoading] = useState(false);
  const [baseSku, setBaseSku] = useState([]);
  const [switchState, setSwitchState] = useState(false);
  const [activePlant, setActivePlant] = useState();
  const { producing_plant_data = [], sku_data = [] } = info;

  useEffect(() => {
    if (activeTab === 'normAdjustment') {
      fetch();
      setActivePlant(producing_plant_data[0]?.producing_plant);
    }
  }, [activeTab]);

  const checkIfFlagged = (requested, primary ) => {
    if (!primary) return false;
    return ( parseFloat(requested?.quantity ?? '') !== parseFloat(primary?.quantity ?? ''))
  };
  const fetch = async (refresh= false, plant='') => {
    if (refresh) {
      try{
        await axios.post(`/costing-request/step/norms/refresh/${rfsNumber}/?plant=${plant}`, {})
      }catch(e){
      }
    }
    try {
      setLoading(true);

      const response = await axios.get(`/costing-request/step/norms/${rfsNumber}`);
      let apiData = response.data?.data;
      const updatedData = apiData.map((ele) => {
        const refData = ele?.reference_bom?.filter(data => data.component_description === ele.component_description);
        // const refData = [{...ele}]
        const isFlagged = checkIfFlagged(ele, refData[0]);
        const hasReference = refData.length > 0 ? true : false
        return {
          ...ele,
          reference_bom: refData,
          flagged: isFlagged,
          hasReference: hasReference
        };
      });
      setBaseSku(updatedData);
    } catch (err) {
      console.log('err : normsCheck.js', err);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = (plant) => {
    fetch(true, plant);
  };

  const handleAutoAdjustment = () => {
    setBaseSku((prevData) =>
      prevData.map((ele) => {
        if (ele.reference_bom?.[0]?.quantity) {
          ele.quantity = ele.reference_bom?.[0]?.quantity;
        }
        return ele;
      }),
    );
  };

  const handleSaveChange = async (componentId) => {
    const selectedSku = baseSku.find((data) => data.component_id === componentId);
    try {
      const payload = {
        component_id: selectedSku.component_id,
        quantity: selectedSku.quantity,
        comment: selectedSku.comment ?? '',
      };

      const res = await axios.post(
        `/costing-request/step/norms/${selectedSku?.costing_request_id}`,
        payload,
      );

    } catch (error) {
      console.error('Error updating SKU:', error);
      // handleNotification('Error', 'Failed to update selected SKU', 'red');
    }
  };

  const handleInputChange = useCallback((id, field, value, prodPlantId) => {
    setBaseSku((prevData) =>
      prevData.map((ele) => {
        if (ele?.component_id === id) {
          ele[field] = value;
          ele['flagged'] = checkIfFlagged(ele, ele?.reference_bom?.[0]);
        }
        return ele;
      }),
    );
  }, []);

  const columns = useMemo(
    () => [
      
      {
        accessorKey: 'component_description',
        header: 'Norm Name',
      },
      {
        accessorKey: 'component',
        header: 'Norm Resource',
      },
      {
        accessorKey: 'currency',
        header: 'Curr',
        grow: false,
        size: 30,
      },
      {
        accessorFn: (row) => row,
        header: 'Total Value (per HL)',
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row?.original?.sku_id || 'key-1'} style={{ marginRight: '8px' }}>
                  Req:{row.original?.scrap ?? ''}
                </span>
              </div>
              {row?.original?.hasReference && (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  Ref:{row.original?.reference_bom?.[0]?.scrap ?? ''}
                </span>
              </div>
              )}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Qty',
        Cell: ({ row }) => {
          const hasMismatch = row.original?.reference_bom?.[0]?.quantity
            ? parseFloat(row.original?.quantity) !==
              parseFloat(row.original?.reference_bom?.[0]?.quantity)
            : false;

          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id || 'key-1'} style={{ marginRight: '8px' }}>
                  Req:
                </span>
                <NumberInput
                  precision={2}
                  min={0}
                  step={0.01}
                  value={
                    parseFloat(row.original?.quantity) ? parseFloat(row.original?.quantity) : 0
                  }
                  styles={(theme) => ({
                    input: {
                      borderColor: hasMismatch ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                    },
                  })}
                  onChange={(val) =>
                    handleInputChange(row.original?.component_id ?? '', 'quantity', val, '')
                  }
                />
              </div>
              {row?.original?.hasReference && (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Ref:
                </span>
                <TextInput
                  value={
                    parseFloat(row.original?.reference_bom?.[0]?.quantity)
                      ? row.original?.reference_bom?.[0]?.quantity
                      : ''
                  }
                  disabled
                />
              </div>
            )}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Qty Unit',
        grow: false,
        size: 30,
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Req: {row.original?.component_unit ?? ''}
                </span>
              </div>
              {row?.original?.hasReference && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span key={row.original.primary?.sku_id} style={{ marginRight: '8px' }}>
                  Ref : {row.original?.reference_bom?.[0]?.component_unit ?? ''}
                </span>
              </div>
              )}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Price',
        Cell: ({ row }) => (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <span key={row.original.requested?.sku_id} style={{ marginRight: '8px' }}>
                Req: {row?.original?.planned_price_1}
              </span>
            </div>
            {row?.original?.hasReference && (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <span style={{ marginRight: '8px' }}>
                Ref: {row?.original?.reference_bom?.planned_price_1}
              </span>
            </div>
            )}
          </div>
        ),
      },
      {
        accessorFn: (row) => row,
        header: 'Price Unit',
        grow: false,
        size: 30,
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Req: {row.original?.price_unit}
                </span>
              </div>
              {row?.original?.hasReference && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '8px' }}>
                  Ref: {row.original?.reference_bom?.price_unit || ' '}
                </span>
              </div>
              )}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Comment',
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ alignItems: 'center', marginBottom: '8px' }}>
                <CustomTextArea
                  value={row.original?.comment ?? ''}
                  placeholder='Comment here'
                  onChange={(value) =>
                    handleInputChange(
                      row.original?.component_id ?? '',
                      'comment',
                      value,
                      row.original?.ppID,
                    )
                  }
                />
              </div>
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Submit',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => handleSaveChange(row.original?.component_id, row.original)}
              className='add-supplier-button'
              color='dark'
              fullWidth
            >
              Submit
            </Button>
          );
        },
      },
    ],
    [handleInputChange, baseSku],
  );
  const rightSideBtns = [
    {
      label : ( <IconRefresh size={16} />),
      onClick: () => fetch(true)
    }, 
    {
      label : "Auto Adjustment of BOM",
      onClick: () =>  handleAutoAdjustment()
    }
  ]
  return (
    <>
        <Space h='sm' />
        <Tabs
          keepMounted={false}
          value={activePlant}
          onTabChange={(value) => setActivePlant(value)}
          color='yellow'
        >
          <Tabs.List>
            {producing_plant_data.map(({ producing_plant, id }, index) => (
              <Tabs.Tab value={producing_plant} className='menu-tabs'>
                {producing_plant}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {producing_plant_data.map(({ producing_plant, id }, index) => {
            return (
              <Tabs.Panel value={producing_plant}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                    marginTop: '10px',
                  }}
                >
                  <Switch
                    label='View Flagged Data Only'
                    size='md'
                    onChange={(e) => setSwitchState(e.target.checked)}
                  />
           
                </div>

                <MantineTable
                  columns={columns}
                  initialData={baseSku.filter(
                    (item) =>
                      (item?.producingPlant?.producing_plant === producing_plant || 
                        item?.pp_id === producing_plant) &&
                       (!switchState ||(switchState && item.flagged))
                  )}
                  enablePinning
                  initialState={{ columnPinning: { 
                    left: ['component_description', 'component',],
                    right:[] 
                  } }}
                  unique={'component'}
                  enableRowSelection={false}
                  loading={loading}
                  showSelectedToggle={false}
                  hideSelectColumn={true}
                  showResetAll={false}
                  enableExport={true}
                  pageSize={10}
                  enableSelectAll={false}
                  rightButtons={rightSideBtns}
                />
              </Tabs.Panel>
            );
          })}
        </Tabs>

    </>
  );
};
