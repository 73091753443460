import React, { useState, useEffect, useContext } from 'react';
import {
  Divider,
  Grid,
  Modal,
  Select,
  Button,
  Textarea,
  FileInput,
  CloseButton,
} from '@mantine/core';
import { UploadIcon } from '@radix-ui/react-icons';
import styled from '@emotion/styled';
import { axios } from '../../utils/axios';
import { showAlert } from '../../utils/alerts';
import { useNavigate } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { moduleRoles } from '../../constants';
import { CustomTextInput } from '../../pages/costingRequest/styles';
import { AppLoader } from '../common/loader';
import { ACCEPTED_MIME_TYPE } from '../../common/dropzone/constants';
import { AuthContext } from '../../contexts/AuthContext';

const Content = styled.span`
  color: #e5b611;
  cursor: pointer;
  font-size: 0.875rem;
`;

export const UserAccessPreview = ({ state, setState, label, value, onChange, ...props }) => {
  const { user = {} } = state;
  const { crUserRoles } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [posted, setPosted] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedPlant, setSelectedPlant] = useState('');
  const [selectedSKUType, setSelectedSKUType] = useState('');
  const [selectedRequestType, setSelectedRequestType] = useState('');
  const [justification, setJustification] = useState('');
  const [justificationError, setJustificationError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [skuTypeError, setSkuTypeError] = useState('');
  const [requestTypeError, setRequestTypeError] = useState('');
  const [plantTypeError, setPlantTypeError] = useState('');
  const [countryTypeError, setCountryTypeError] = useState('');
  const [zoneTypeError, setZoneTypeError] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [attachTypeError, setAttachTypeError] = useState('');
  const [rejectedUploads, setRejectedUploads] = useState([]);
  const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'msg', 'eml'];
  const [submitLoader, setSubmitLoader] = useState(false);

  const [fetchedOptions, setFetchedOptions] = useState({
    Zone: [],
    Country: {},
    Plant: {},
    skus: ['Filling', 'Repack'],
    typeOfRequest: ['Costing', 'Transfer Pricing', 'Both'],
  });

  useEffect(() => {
    if (selectedRole) {
      fetchOptions(selectedRole);
    }
  }, [selectedRole]);

  const fetchOptions = async (roleAlias) => {
    try {
      const response = await axios.get('/user/v2/options', {
        params: { role: roleAlias },
      });

      if (response.data.success) {
        const { zone_country_plant, skus, data } = response.data;

        setFetchedOptions({
          ...data,
          Zone: Object.keys(zone_country_plant || {}),
          Country: zone_country_plant || {},
          skus: skus || [],
          typeOfRequest: fetchedOptions.typeOfRequest,
          Plant: {},
        });
      } else {
        console.error('Error fetching options:', response.data);
      }
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  const {
    is_scoped_to_country,
    is_scoped_to_plant,
    is_scoped_to_type_of_sku,
    is_scoped_to_type_of_request,
  } = fetchedOptions || {};

  const handleSubmit = async () => {
    let isValid = true;

    if (!selectedRole) {
      setRoleError('Role is required');
      isValid = false;
    }
    if (is_scoped_to_country && !selectedCountry) {
      setCountryTypeError('Country is required');
      isValid = false;
    }
    if (is_scoped_to_plant && !selectedPlant) {
      setPlantTypeError('Plant is required');
      isValid = false;
    }
    if (is_scoped_to_country && !selectedZone) {
      setZoneTypeError('Zone is required');
      isValid = false;
    }
    if (is_scoped_to_type_of_sku && !selectedSKUType) {
      setSkuTypeError('Type of SKU is required');
      isValid = false;
    }
    if (is_scoped_to_type_of_request && !selectedRequestType) {
      setRequestTypeError('Type of Request is required');
      isValid = false;
    }
    if (attachments.length === 0) {
      setAttachTypeError('Attachment is required');
      isValid = false;
    }
    if (!justification.trim()) {
      setJustificationError('Justification is required');
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    setLoading(true);
    setPosted(true);

    try {
      let accessToken = '';
      if (isAuthenticated) {
        const request = {
          scopes: ['User.Read'],
          account: accounts[0],
        };

        try {
          const response = await instance.acquireTokenSilent(request);
          accessToken = response.accessToken;
        } catch (authError) {
          showAlert({ message: 'Authentication failed. Please try again.' }, 'error');
          setLoading(false);
          return;
        }
      }

      const formData = new FormData();
      formData.append('access_token', accessToken);
      formData.append('role_requested', selectedRole || '');
      formData.append('plant', selectedPlant || '');
      formData.append('country', selectedCountry || '');
      formData.append('type_of_sku', selectedSKUType || '');
      formData.append('type_of_request', selectedRequestType || '');
      formData.append('justification', justification || '');

      attachments.forEach((file) => {
        formData.append('attachments', file);
      });

      const response = await axios.post('/register/v2', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.success) {
        showAlert({
          message: 'Requested Role sent for approval.',
          type: 'success',
        });
        navigate('/costingRequests/home');
      } else {
        showAlert(
          { message: response.data.message || 'Role Requested failed. Please try again.' },
          'error',
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }; // Error handling

  const handleError = (error) => {
    const errorMessage = error.response?.data?.message || 'Registration failed. Please try again.';
    showAlert({ message: errorMessage }, 'error');
  };

  const handleFileSelect = (files) => {
    const newAttachments = [...attachments];
    const newRejectedUploads = [...rejectedUploads];

    Array.from(files).forEach((file) => {
      if (validateFileExtension(file.name)) {
        newAttachments.push(file);
      } else {
        newRejectedUploads.push(file.name);
      }
    });

    setAttachments(newAttachments);
    setRejectedUploads(newRejectedUploads);
  };

  const validateFileExtension = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    return allowedExtensions.includes(extension);
  };

  const handleFileRemove = (index) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  const handleRoleChange = (value) => {
    setSelectedRole(value);
    setSelectedZone(null);
    setSelectedCountry(null);
    setSelectedPlant(null);
    setSelectedSKUType(null);
  };

  const handleZoneChange = (value) => {
    setSelectedZone(value);
    setSelectedCountry(null);
    setSelectedPlant(null);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setSelectedPlant(null);
  };

  const availableCountries = selectedZone
    ? Object.keys(fetchedOptions.Country[selectedZone] || {})
    : [];
  const availablePlants = selectedCountry
    ? fetchedOptions.Country[selectedZone]?.[selectedCountry] || []
    : [];

  return (
    <Modal
      size='50%'
      opened={state.open}
      onClose={() => setState({ open: false, user: null })}
      title=''
    >
      <Grid>
        <Grid.Col md={6} lg={6}>
          <CustomTextInput disabled label='Name' value={user.name} withAsterisk />
        </Grid.Col>
        <Grid.Col md={6} lg={6}>
          <CustomTextInput disabled label='Email' value={user.email} withAsterisk />
        </Grid.Col>
        <Divider />

        <Grid.Col md={6} lg={6}>
          <Select
            label='Role'
            value={selectedRole}
            onChange={(value) => {
              handleRoleChange(value);
              if (value) setRoleError('');
            }}
            data={moduleRoles['Costing Request'].filter(({ label }) => {
              if (!['Requestor', 'User Admin'].includes(label)) return true;

              return !crUserRoles.includes(label);
            })}
            withAsterisk
            error={roleError}
          />
        </Grid.Col>
        {is_scoped_to_country && (
          <Grid.Col md={6} lg={6}>
            <Select
              label='Zone'
              value={selectedZone}
              withAsterisk
              error={zoneTypeError}
              onChange={(value) => {
                handleZoneChange(value);
                if (value) setZoneTypeError('');
              }}
              data={fetchedOptions.Zone.map((zone) => ({
                value: zone,
                label: zone,
              }))}
            />
          </Grid.Col>
        )}
        {is_scoped_to_country && (
          <Grid.Col md={6} lg={6}>
            <Select
              label='Country'
              value={selectedCountry}
              onChange={(value) => {
                handleCountryChange(value);
                if (value) setCountryTypeError('');
              }}
              error={countryTypeError}
              withAsterisk
              data={availableCountries.map((country) => ({
                value: country,
                label: country,
              }))}
            />
          </Grid.Col>
        )}

        {selectedCountry && is_scoped_to_plant && (
          <Grid.Col md={6} lg={6}>
            <Select
              label='Producing Plant'
              value={selectedPlant}
              error={plantTypeError}
              withAsterisk
              onChange={(value) => {
                setSelectedPlant(value);
                if (value) setPlantTypeError('');
              }}
              data={availablePlants.map((plant) => ({
                value: plant,
                label: plant,
              }))}
            />
          </Grid.Col>
        )}

        {is_scoped_to_type_of_sku && (
          <Grid.Col md={6} lg={6}>
            <Select
              label='Type of SKU'
              value={selectedSKUType}
              withAsterisk
              error={skuTypeError}
              onChange={(value) => {
                setSelectedSKUType(value);
                if (value) setSkuTypeError('');
              }}
              data={fetchedOptions.skus.map((sku) => ({
                value: sku,
                label: sku,
              }))}
            />
          </Grid.Col>
        )}
        <Divider />
        {is_scoped_to_type_of_request && (
          <Grid.Col md={6} lg={6}>
            <Select
              label='Type of Request'
              value={selectedRequestType}
              withAsterisk
              error={requestTypeError}
              onChange={(value) => {
                setSelectedRequestType(value);
                if (value) setRequestTypeError('');
              }}
              data={fetchedOptions.typeOfRequest.map((requestType) => ({
                value: requestType,
                label: requestType,
              }))}
            />
          </Grid.Col>
        )}

        <Grid.Col md={6} lg={6}>
          <div style={{ marginBottom: '8px' }}>
            <FileInput
              withAsterisk
              radius='md'
              accept={ACCEPTED_MIME_TYPE}
              placeholder={
                <span>
                  <UploadIcon style={{ marginLeft: '1px' }} /> Upload approval mail
                </span>
              }
              name='managerApproval'
              label='Line Manager Approval'
              value={attachments}
              onChange={(value) => {
                handleFileSelect(value);
                if (value.length > 0) setAttachTypeError('');
              }}
              error={attachTypeError}
              onRemove={handleFileRemove}
              multiple
            />
            {attachments.length > 0 && (
              <div style={{ marginTop: '8px' }}>
                {attachments.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      padding: '4px',
                    }}
                  >
                    <span>{file.name}</span>
                    <Button
                      variant='subtle'
                      color='red'
                      onClick={() => handleFileRemove(index)}
                      compact
                      style={{
                        marginLeft: '8px',
                        padding: '0',
                        border: 'none',
                        background: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: 'red',
                      }}
                    >
                      <CloseButton aria-label='Close modal' />
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div style={{ fontSize: '0.875rem', color: '#6B7280', marginTop: '4px' }}>
            Allowed file types: jpeg, jpg, png, eml, msg
          </div>
        </Grid.Col>

        <Grid.Col md={12} lg={12}>
          <Textarea
            label='Justification'
            value={justification}
            withAsterisk
            error={justificationError}
            onChange={(e) => {
              setJustification(e.currentTarget.value);
              if (e.currentTarget.value) {
                setJustificationError('');
              }
            }}
          />
        </Grid.Col>

        <Grid.Col span={10} />
        <Grid.Col span={2}>
          <Button color='dark' fullWidth onClick={() => handleSubmit()}>
            {loading || submitLoader ? <AppLoader size='sm' variant='dark' /> : 'Submit'}
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};
