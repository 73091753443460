import pureAxios from 'axios';
import { deleteCookie } from './helpers';

export const axios = pureAxios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  //baseURL: URLS.local,
  headers: {
    common: {
      Authorization: `${localStorage.getItem('token')}`,
    },
  },
});

axios.interceptors.response.use(
  function (response) {
    // if (response?.data?.message) showAlert(response.data);
    return response;
  },
  function (error) {
    // if (error?.response?.data?.message) showAlert(error.response.data, 'error');
    // Do something with response error
    if ([401].includes(error.response?.status)) {
      localStorage.clear();
      deleteCookie('token');
      window.location.href = '/';
    }
    if (error.response?.status === 403) {
      window.location.href = '/unauth';
    }
    return Promise.reject(error);
  },
);
