import { Accordion, Grid } from '@mantine/core';
import { useContext } from 'react';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { useAccordionStyles } from '../../styles';
import { getValueByKey } from '../../../../utils/helpers';

const infoItems = [
  {
    name: 'Costing Request',
    key: 'costing_request_id',
  },
  {
    name: 'Requester Name',
    key: 'user.name',
  },
  {
    name: 'Urgency',
    key: 'urgency',
  },
  {
    name: 'Type of Goods',
    key: 'types_of_good',
  },
  {
    name: 'Type of SKU',
    key: 'types_of_sku',
  },
];

export const ProjectOverview = () => {
  const { classes } = useAccordionStyles();
  const { info } = useContext(CostingRequestsContext);

  return (
    <Accordion
      iconPosition='right'
      defaultValue={'0'}
      classNames={classes}
      className={classes.root}
    >
      <Accordion.Item className='project-name' value={'0'}>
        <Accordion.Control>{`${info?.costing_request_id}`}</Accordion.Control>
        <Accordion.Panel style={{ padding: '5px 16px 16px' }}>
          <Grid>
            {infoItems.map((item, index) => (
              <Grid.Col span={4} key={`project_overview_${index}`}>
                <b>{`${item.name}: `}</b>
                {getValueByKey(info, item.key)}
              </Grid.Col>
            ))}
          </Grid>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
