import React, { useEffect, useState } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  ActionIcon,
  Button,
  Flex,
  Tooltip,
  Switch,
  Select,
  MantineProvider,
  useMantineTheme,
  Grid,
} from '@mantine/core';
import {
  IconEdit,
  IconSearch,
  IconSearchOff,
  IconRefresh,
  IconEditCircle,
  IconEditCircleOff,
  IconTrash,
} from '@tabler/icons-react';
import { CalendarIcon } from '@modulz/radix-icons';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment';

//CSV Download
import { HeaderLeftPanel, HeaderStyles, LeftItems, RightItems } from './styles';
import { CustomTextInput } from '../../inputs/CustomTextInput';
import { DownloadExcel } from '../../excel';
import { TableSearch } from './search';

const MantineTable = ({
  columns,
  setValidationErrors = () => {},
  unique,
  enableEditing = false,
  readOnly,
  enableExport = false,
  csvFilename,
  initialData = [],
  loading = true,
  handleRowSelection = () => {},
  activeRows = {},
  enableRowSelection = true,
  resetTableData = () => {},
  enableExpanding = false,
  enablePinning = false,
  enableStickyFooter = false,
  initialState = null,
  hideSelectColumn = true,
  sendFilteredData = () => {},
  showSelectedToggle = true,
  pageSize = 5,
  enablePagination = true,
  enableBottomToolbar = true,
  enableTopToolbar = true,
  showResetAll = true,
  enableColumnFilters = true,
  enableColumnActions = true,
  showEditActions = () => {
    return true;
  },
  positionToolbarAlertBanner = 'top',
  editAll = false,
  applyToAll = () => {},
  alwaysShowEditAll = false,
  fullScreen = true,
  multiRowSelection = true,
  mantineTableHeadProps = { zIndex: 0 },
  mantinePaperProps = {},
  handleSaveRow = () => {},
  handleDeleteRow = null,
  rightButtons = [],
  enableSelectAll = true,
  isRowDisabled = null,
  leftButtons = [],
}) => {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [rowSelection, setRowSelection] = useState(activeRows);
  const [filteredRows, setFilteredRows] = useState([]);
  const globalTheme = useMantineTheme;

  //Custom search
  const [toBeSearched, setToBeSearched] = useState('');

  //Search Icon
  const [searchOn, setSearchOn] = useState(false);

  //All Select
  const [allSelected, setAllSelected] = useState(false);

  const [data, setData] = useState([]);
  const [successData, setSuccessData] = useState([]);
  const [allEditOn, setAllEditOn] = useState(false);
  const [applyAllColumn, setApplyAllColumn] = useState(null);
  const [tempApplyAllValue, setTempApplyAll] = useState(null);

  useEffect(() => {
    if (Object.keys(rowSelection).length !== Object.keys(activeRows).length) {
      setRowSelection(activeRows);
    }
  }, [activeRows]);

  useEffect(() => {
    if (!allEditOn) {
      setApplyAllColumn(null);
      setTempApplyAll(null);
    }
  }, [allEditOn]);

  useEffect(() => {
    initializeTable();
  }, [initialData]);

  const initializeTable = () => {
    setData(initialData);
    setSuccessData(initialData);

    sendFilteredData(initialData);
  };

  useEffect(() => {
    if (data.length > 0) {
      setFilteredRows(data);
    }
  }, [data.length]);

  const filterMultiSearch = (input, filteredData) => {
    setData(
      input.length > 0
        ? filteredData
        : switchChecked && Object.keys(selected).length
        ? selected
        : successData,
    );
    setFilteredRows(input.length > 0 ? filteredData : successData);
  };

  const resetAll = () => {
    setSearchOn(false);
    setAllSelected(false);
    setRowSelection({});
    setSwitchChecked(false);
    setData(successData);
    setFilteredRows(successData);
    sendFilteredData(successData);
    handleRowSelection({});
    resetTableData();
  };

  const getApplyAllData = (type = 'type') => {
    const dataArray = applyAllColumn.split('|');
    return type === 'type' ? dataArray[1] : dataArray[0];
  };

  const initiateSaveRow = async ({ table, row, values }) => {
    await handleSaveRow({ ...row.original, ...values });
    table.setEditingRow(null);
  };

  const table = useMantineReactTable({
    columns,
    data: data,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: enableEditing,
    getRowId: (row) => row[unique],
    enableRowSelection: hideSelectColumn
      ? false
      : (row) => {
          if (isRowDisabled) {
            return !isRowDisabled(row.original);
          } else if (row.original.hasOwnProperty('disabled')) {
            return !row.original.disabled;
          } else {
            return enableRowSelection;
          }
        },
    selectDisplayMode: 'checkbox',
    enableGlobalFilter: false,
    selectAllMode: 'all',
    enableColumnFilters: enableColumnFilters,
    enableColumnActions: enableColumnActions,
    enableExpanding: enableExpanding,
    enablePinning: enablePinning,
    enablePagination: enablePagination,
    enableBottomToolbar: enableBottomToolbar,
    enableTopToolbar: enableTopToolbar,
    enableStickyFooter: enableStickyFooter,
    positionToolbarAlertBanner: positionToolbarAlertBanner,
    enableFullScreenToggle: fullScreen,
    enableMultiRowSelection: multiRowSelection,
    enableSelectAll: enableSelectAll,
    autoResetPageIndex: false,
    initialState: initialState
      ? initialState
      : {
          showColumnFilters: true,
          density: 'xs',
          pagination: { pageSize: pageSize, pageIndex: 0 },
        },
    displayColumnDefOptions: {
      'mrt-row-select': {
        enableHiding: alwaysShowEditAll,
      },
    },
    mantineTableProps: {
      highlightOnHover: false,
      withColumnBorders: true,
      withBorder: 'light',
    },
    mantineTableHeadProps: mantineTableHeadProps,
    mantinePaperProps: mantinePaperProps,
    mantineTableFooterProps: {
      style: { zIndex: 0 },
    },
    mantineTableContainerProps: {
      sx: {
        zIndex: 0,
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: (info) => initiateSaveRow(info),
    renderRowActions: ({ row, table }) => (
      <Flex style={{ zIndex: 99999 }} gap='md'>
        <ActionIcon disabled={readOnly} onClick={() => table.setEditingRow(row)}>
          <IconEdit />
        </ActionIcon>

        {handleDeleteRow && (
          <ActionIcon color='red' onClick={() => handleDeleteRow(row.original)}>
            <IconTrash />
          </ActionIcon>
        )}
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <>
          <HeaderLeftPanel>
            {showSelectedToggle && (
              <Tooltip label='Show All Selected'>
                <Switch
                  disabled={selected.length === 0 && !switchChecked}
                  checked={switchChecked}
                  onChange={(event) => setSwitchChecked(event.currentTarget.checked)}
                />
              </Tooltip>
            )}

            {showResetAll && (
              <Tooltip label='Reset All'>
                <IconRefresh onClick={resetAll} />
              </Tooltip>
            )}

            <div
              onClick={() => {
                if (searchOn) {
                  setFilteredRows(successData);
                  sendFilteredData(successData);
                }
                setSearchOn(searchOn ? false : true);
                setAllEditOn(false);
              }}
            >
              <Tooltip label='Search All'>{searchOn ? <IconSearchOff /> : <IconSearch />}</Tooltip>
            </div>

            {(alwaysShowEditAll || (Object.keys(rowSelection).length > 0 && editAll)) && (
              <Tooltip label='Edit All Selected'>
                <div
                  onClick={() => {
                    const value = allEditOn ? false : true;
                    if (alwaysShowEditAll) {
                      if (!value) table.resetRowSelection();
                      const column = table.getColumn('mrt-row-select');
                      if (column) column.toggleVisibility(value);
                    }

                    setAllEditOn(value);
                    setSearchOn(false);
                  }}
                >
                  {allEditOn ? <IconEditCircleOff /> : <IconEditCircle />}
                </div>
              </Tooltip>
            )}
          </HeaderLeftPanel>

          {searchOn && (
            <TableSearch
              columns={columns}
              toBeSearched={toBeSearched}
              setToBeSearched={setToBeSearched}
              filterMultiSearch={filterMultiSearch}
              data={successData}
            />
          )}

          {allEditOn && (
            <Grid gutter='md' grow justify='center' align='center' style={{ minWidth: '40%' }}>
              <Grid.Col span={6}>
                <Select
                  value={applyAllColumn}
                  disabled={readOnly}
                  label='Select the column to edit'
                  placeholder='Select The Column'
                  data={columns
                    .filter((c) => c.enableEditing)
                    .map((column) => {
                      return {
                        value: column.accessorKey + '|' + column.editVariant,
                        label: column.header,
                      };
                    })}
                  onChange={(e) => {
                    setApplyAllColumn(e);
                    setTempApplyAll(null);
                  }}
                  withinPortal
                />
              </Grid.Col>

              <Grid.Col span={4}>
                {applyAllColumn && getApplyAllData() === 'select' && (
                  <Select
                    value={tempApplyAllValue}
                    label='Select'
                    placeholder='Select'
                    data={
                      columns.find((c) => getApplyAllData('key') === c.accessorKey)
                        ?.mantineEditSelectProps?.data || []
                    }
                    onChange={(value) => setTempApplyAll(value)}
                    withinPortal
                  />
                )}

                {applyAllColumn && getApplyAllData() === 'text' && (
                  <div style={{ marginTop: '12px' }}>
                    <CustomTextInput
                      value={tempApplyAllValue}
                      label='Please type here'
                      onChange={(value) => setTempApplyAll(value)}
                    />
                  </div>
                )}

                {applyAllColumn && getApplyAllData() === 'datepicker' && (
                  <DatePickerInput
                    styles={{
                      arrow: {
                        display: 'block',
                      },
                      dropdown: {
                        display: 'block',
                      },
                    }}
                    style={{ marginTop: '22px' }}
                    onChange={(event) =>
                      setTempApplyAll(
                        moment(event).isValid() ? moment(event).format('YYYY-MM-DD') : null,
                      )
                    }
                    minDate={
                      columns.find((c) => getApplyAllData('key') === c.accessorKey)
                        ?.mantineEditSelectProps?.data?.minDate
                    }
                    initialMonth={
                      columns.find((c) => getApplyAllData('key') === c.accessorKey)
                        ?.mantineEditSelectProps?.data?.initialMonth
                    }
                    numberOfColumns={2}
                    radius='md'
                    icon={<CalendarIcon color='#e5b611' />}
                    popoverProps={{ withinPortal: true }}
                  />
                )}
              </Grid.Col>

              <Grid.Col span={2}>
                <Button
                  style={{ marginTop: '22px' }}
                  onClick={() =>
                    applyToAll(
                      tempApplyAllValue,
                      applyAllColumn.split('|')[0],
                      table.getFilteredSelectedRowModel(),
                    )
                  }
                  className='custom-mantine-button'
                  disabled={!tempApplyAllValue}
                >
                  Save
                </Button>
              </Grid.Col>
            </Grid>
          )}
        </>
      );
    },
    state: {
      isLoading: loading,
      showProgressBars: loading,
      rowSelection,
      allEditOn,
    },
    onRowSelectionChange: setRowSelection,
  });

  const columnFilterResults = table.getFilteredRowModel();

  useEffect(() => {
    if (table && alwaysShowEditAll) {
      const column = table.getColumn('mrt-row-select');
      if (column) column.toggleVisibility(false);
    }
  }, [alwaysShowEditAll]);

  useEffect(() => {
    sendFilteredData(columnFilterResults.rows.map((row) => row.original));
  }, [columnFilterResults]);

  const updateSelected = () => {
    var filtered = filteredRows.filter((item, index) => {
      return Object.keys(rowSelection).indexOf((item[unique] || '').toString()) !== -1;
    });

    setSelected(filtered);
  };

  useEffect(() => {
    updateSelected();
    handleRowSelection(rowSelection);
  }, [rowSelection, filteredRows]);

  useEffect(() => {
    if (switchChecked || allSelected) setData(selected);
    else if ((!switchChecked || !allSelected) && !searchOn) setData(successData);
    else if ((!switchChecked || !allSelected) && searchOn) setData(filteredRows);
    else setData(successData);
  }, [switchChecked, selected, data, searchOn]);

  const readyExport = () => {
    const columnsHeaderMap = columns.reduce((acc, column) => {
      if (column.id !== 'logs') {
        acc[column.accessorKey || column.id] = column.header;
      }
      return acc;
    }, {});

    const dataToExport = initialData.map((item) => {
      return Object.keys(item).reduce((row, key) => {
        if (columnsHeaderMap[key]) {
          row[columnsHeaderMap[key]] = item[key] || '';
        }

        return row;
      }, {});
    });

    return dataToExport;
  };

  return (
    <>
      <HeaderStyles>
        <LeftItems>
          {leftButtons &&
            leftButtons.map((buttons, index) => <div key={index}>{buttons.button}</div>)}
        </LeftItems>
        <RightItems>
          {rightButtons &&
            rightButtons.map((item, index) => (
              <div key={index}>
                {item.button ? (
                  item.button
                ) : (
                  <Button
                    variant='gradient'
                    size='sm'
                    color='dark'
                    key={index}
                    onClick={item.onClick}
                  >
                    {item.label}
                  </Button>
                )}
              </div>
            ))}
          {enableExport && <DownloadExcel fetch={() => readyExport()} filename={csvFilename} />}
        </RightItems>
      </HeaderStyles>

      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 7 }}>
        <MantineReactTable
          table={table}
          mantineTableBodyProps={{
            striped: true,
            sx: {
              minHeight: 'unset',
            },
          }}
        />
      </MantineProvider>
    </>
  );
};

export default MantineTable;
