import React from 'react';
import { Button, Grid, Menu } from '@mantine/core';
import csvDownload from 'json-to-csv-export';
import moment from 'moment';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export const DownloadExcel = ({ data, filename = 'Data', fetch = null }) => {
  const downloadCsv = (rows = []) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const headers = Object.keys(rows[0] || []);

    dataToExport.data = getDataToExport(rows);
    dataToExport.filename = `Download_${filename}_` + moment().format('YYYY-MM-DD HH:mm:ss');
    dataToExport.headers = headers;
    csvDownload(dataToExport);
  };

  const getDataToExport = (data) => {
    const csvArray = data.map((element) => Object.values(element).map((i, index) => i));
    return csvArray;
  };

  const downloadXlsx = (rows) => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${filename}_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
  };

  return (
    <Grid justify='flex-end'>
      <Grid.Col
        span={3}
        style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}
      >
        <Menu shadow='md' width={150}>
          <Menu.Target>
            <Button variant='gradient' size='sm' color='dark'>
              Export
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              component='button'
              onClick={() => {
                const items = fetch ? fetch() : data;
                downloadCsv(items);
              }}
            >
              CSV
            </Menu.Item>

            <Menu.Item
              component='button'
              onClick={() => {
                const items = fetch ? fetch() : data;
                downloadXlsx(items);
              }}
            >
              XLSX
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Grid.Col>
    </Grid>
  );
};
