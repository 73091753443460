

const makeUniqueByComponent = (array) => {
  const seen = new Set();
  return array.filter(item => {
    const isDuplicate = seen.has(item.component);
    seen.add(item.component);
    return !isDuplicate;
  });
};

export const checkIfFlagged = (requested, reference) => {
  if (!reference){return false}
  return (
    parseFloat(requested?.quantity ?? '') !== parseFloat(reference?.quantity ?? '') ||
    parseFloat(requested?.scrap  ?? '') !== parseFloat(reference?.scrap  ?? '') ||
    parseFloat(requested?.RequestedSkuCostEstimation?.total_value ?? '') !==
     parseFloat(reference?.PrimaryRefSkuCostEstimation?.total_value ?? '')
  );
};


export const formatSkus = (requestedSkus, referenceSkus) => {
  const uniqueRequestedSkus = makeUniqueByComponent(requestedSkus);
  const uniqueReferenceSkus = makeUniqueByComponent(referenceSkus);
  let referenceSkusCopy = [...uniqueReferenceSkus];
  const updatedData = uniqueRequestedSkus.map((ele) => {
    const referenceIndex = referenceSkusCopy.findIndex(ref => ref.component === ele.component);
    const matchingReference = referenceIndex > -1 ? referenceSkusCopy[referenceIndex] : null;
    if (referenceIndex > -1) {
      referenceSkusCopy.splice(referenceIndex, 1);
    }
    return {
      ...ele,
      isReference: false,
      flagged: checkIfFlagged(ele, matchingReference),
      hasReference: !!matchingReference,
      reference: matchingReference,
      isChanged: false
    };
  });

  const remainingReferenceData = referenceSkusCopy.map(ref => ({
    ...ref,
    isReference: true,
    hasReference: false,
    isChanged: false,
  }));
  return [...updatedData, ...remainingReferenceData];
};
