import MantineTable from '../../../../common/table/mantine';
import { useContext, useMemo } from 'react';
import moment from 'moment';
import { IconRefresh } from '@tabler/icons-react';
import { Grid, Tabs } from '@mantine/core';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';

export const Mother = ({ activeSubTab, loading, skus, fetch, activePlant, setActivePlant }) => {
  const { info } = useContext(CostingRequestsContext);
  const { producing_plant_data = [] } = info;

  const columns = useMemo(
    () => [
      {
        accessorKey: 'sku_number',
        header: 'SKU Number',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Description',
      },
      {
        accessorKey: 'material_number',
        header: 'Material Number',
      },
      {
        accessorKey: 'material_description',
        header: 'Material Description',
      },

      {
        accessorKey: 'urgency',
        header: 'Urgency',
      },
      {
        accessorKey: 'producing_plant',
        header: 'Producing Plant',
      },
      {
        accessorKey: 'base_unit',
        header: 'Base Unit',
      },
      {
        accessorKey: 'uom',
        header: 'UOM',
      },
      {
        accessorKey: 'currency',
        header: 'Currency',
      },
      {
        accessorKey: 'cost_value',
        header: 'Cost Value',
      },
      {
        accessorKey: 'mother_costing_request_id',
        header: 'Costing Request ID',
      },
      {
        accessorKey: 'mother_costing_request_created_on',
        header: 'Costing Request Created On',
      },
      {
        accessorKey: 'monther_cr_status',
        header: 'CR Status',
      },
      {
        accessorKey: 'fpna_analyst_responsible',
        header: 'FPNA Analyst responsible',
      },
    ],
    [skus],
  );

  const rightButtons = [{ label: <IconRefresh size={16} />, onClick: () => fetch(true) }];

  return (
    <>
      {' '}
      <Tabs value={activePlant} onTabChange={(value) => setActivePlant(value)} color='yellow'>
        <Tabs.List>
          {producing_plant_data.map(({ producing_plant, id }, index) => (
            <Tabs.Tab value={producing_plant} key={index} className='menu-tabs'>
              {producing_plant}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {producing_plant_data.map(({ producing_plant, id }, index) => {
          return (
            <Tabs.Panel value={producing_plant} key={index}>
              <Grid>
                <Grid.Col span={12}>
                  <MantineTable
                    columns={columns}
                    initialData={(skus || []).filter(
                      (sku) => sku.asm && sku.producing_plant === producing_plant,
                    )}
                    unique={'sku'}
                    enableRowSelection={false}
                    loading={loading}
                    showSelectedToggle={false}
                    hideSelectColumn={true}
                    showResetAll={false}
                    enableExport={true}
                    rightButtons={rightButtons}
                    csvFilename={`bom_component_price_monther_${moment().format('YYYY-MM-DD')}`}
                    pageSize={10}
                  />
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          );
        })}
      </Tabs>
    </>
  );
};
