import { useParams, useSearchParams } from 'react-router-dom';
import { Space, Tabs } from '@mantine/core';
import { Overview } from './overview';
import { BomCreation } from './bomCreation';
import { PvLine } from './pvLine';
import { tabStyles } from '../../../utils/helpers';
import RfsLayout from '../../../components/layouts/RfsLayout';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { useContext, useEffect, useState } from 'react';
import { CostingRequestsContext } from '../../../contexts/CostingRequest';
import { AppLoader } from '../../../components/common/loader';
import { ProjectOverview } from './projectOverview';
import { Logs } from './logs';
import { BomComponentPrice } from './bomComponentPrice';
import { NormsCheck } from './normsCheck';
import { BudgetBomAdjustment } from './budgetBomAdjustment';
import { ReferenceSKU } from './referenceSKU';
import { Vic } from './vic';

const tabs = [
  {
    name: 'Overview',
    value: 'overview',
    component: (props) => <Overview {...props} />,
  },

  {
    name: 'Costing BOM Creation Check',
    value: 'bomCreation',
    component: (props) => <BomCreation {...props} />,
  },
  {
    name: 'PV Line Item Check',
    value: 'pvLine',
    component: (props) => <PvLine {...props} />,
  },
  {
    name: 'BOM Component Price',
    value: 'bomComponentPrice',
    component: (props) => <BomComponentPrice {...props} />,
  },
  {
    name: 'Reference SKU Selection',
    value: 'referenceSKUSelection',
    component: (props) => <ReferenceSKU {...props} />,
  },
  {
    name: 'Budget BOM Adjustment',
    value: 'budgetBomAdjustment',
    component: (props) => <BudgetBomAdjustment {...props} />,
  },

  {
    name: 'NORM Adjustment',
    value: 'normAdjustment',
    component: (props) => <NormsCheck {...props} />,
  },
  {
    name: 'VIC Submission',
    value: 'vicSubmission',
    component: (props) => <Vic {...props} />,
  },
];

export const SubmittedRequest = () => {
  const { rfsNumber } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getRequestDetails, loaders } = useContext(CostingRequestsContext);
  const [logsModal, setLogsModal] = useState({ open: false, logs: [] });

  useEffect(() => {
    getRequestDetails(rfsNumber);
  }, [rfsNumber]);

  if (loaders.info) {
    return (
      <RfsLayout>
        <ContentPaper>
          <AppLoader center size='md' />
        </ContentPaper>
      </RfsLayout>
    );
  }

  return (
    <RfsLayout>
      <Tabs
        keepMounted={false}
        value={searchParams.get('tab') || 'overview'}
        onTabChange={(value) => setSearchParams({ tab: value })}
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
      >
        <Tabs.List>
          {tabs.map((tab, index) => (
            <Tabs.Tab value={tab.value} key={`submitted_${index}`} className='menu-tabs'>
              {tab.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabs.map((tab, index) => (
          <Tabs.Panel value={tab.value} key={`submitted_content_${index}`}>
            {['referenceSKUSelection', 'budgetBomAdjustment'].includes(tab.value) ? (
              tab.component({
                logsModal,
                setLogsModal,
                activeTab: searchParams.get('tab'),
              })
            ) : (
              <ContentPaper footer={null}>
                <ProjectOverview />
                <Space h='sm' />
                {tab.component({
                  logsModal,
                  setLogsModal,
                  activeTab: searchParams.get('tab'),
                })}
              </ContentPaper>
            )}
          </Tabs.Panel>
        ))}
      </Tabs>

      {logsModal.open && <Logs logsModal={logsModal} setLogsModal={setLogsModal} />}
    </RfsLayout>
  );
};
